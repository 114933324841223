import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from "@angular/core";
import { NgTemplateOutlet } from "@angular/common";
import { Action } from "@/types/action.interface";
import { ItIconComponent } from "design-angular-kit";
import { RouterLink } from "@angular/router";
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "cup-action-button",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, ItIconComponent, RouterLink],
  styles: ``,
  template: `
    @let actionData = action();
    @let anchorHref = href() || actionData.href;

    @if (anchorHref) {
      @if (isExternalHref()) {
        <a
          [href]="anchorHref"
          [attr.aria-label]="actionData.ariaLabel"
          class="btn p-0 list-item icon-left"
        >
          <ng-container [ngTemplateOutlet]="buttonContent" />
        </a>
      } @else {
        <a
          [routerLink]="anchorHref.toString()"
          [attr.aria-label]="actionData.ariaLabel"
          class="btn p-0 list-item icon-left"
        >
          <ng-container [ngTemplateOutlet]="buttonContent" />
        </a>
      }
    } @else {
      <button
        type="button"
        [attr.aria-label]="actionData.ariaLabel"
        (click)="actionClick.emit(actionData)"
        class="btn p-0 list-item icon-left"
      >
        <ng-container [ngTemplateOutlet]="buttonContent" />
      </button>
    }

    <ng-template #buttonContent>
      <span class="d-flex text-start align-items-center gap-2">
        @if (actionData.icon && !actionData.isIconOnRight) {
          <it-icon [name]="actionData.icon" [color]="iconColor" />
        }

        <span
          class="text text-uppercase small fw-semibold"
          [class.text-danger]="actionData.severity === 'high'"
        >
          {{ actionData.label }}
        </span>

        @if (actionData.icon && actionData.isIconOnRight) {
          <it-icon [name]="actionData.icon" [color]="iconColor" />
        }
      </span>
    </ng-template>
  `,
})
export class ActionButtonComponent {
  action = input.required<Action>();
  href = input<SafeResourceUrl>("");
  isExternalHref = input(false);
  actionClick = output<Action>();

  get iconColor() {
    if (this.action().relevance === "primary-task") {
      return "white";
    }

    if (this.action().severity === "high") {
      return "danger";
    }

    return "primary";
  }
}
