import { Directive, input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Destroyable } from "@/classes/destroyable";

@Directive()
export class FormBaseUtils extends Destroyable {
  formGroup = input.required<FormGroup>();
  name = input.required<string>();

  get control() {
    return this.formGroup().get(this.name())!;
  }

  get errorEntries() {
    if (!this.control.errors) return [];

    return Object.entries(this.control.errors || {}).map(([key, value]) => ({
      id: key,
      message: value,
    }));
  }
}
