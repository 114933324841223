import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const fadeInAnimation = trigger("fadeIn", [
  state(
    "visible",
    style({
      transform: "none",
      opacity: 1,
    })
  ),
  transition(":enter", [
    style({ transform: "translateY(4px)", opacity: 0 }),
    animate(
      "200ms ease-in-out",
      style({ transform: "translateY(0)", opacity: 1 })
    ),
  ]),
  transition(":leave", [
    animate(
      "200ms ease-in-out",
      style({ transform: "translateY(-4px)", opacity: 0 })
    ),
  ]),
]);
