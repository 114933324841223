import { Pipe, PipeTransform } from "@angular/core";
import { ActionRelevance } from "@/types/action-relevance.type";
import { ButtonColor } from "design-angular-kit";

@Pipe({
  name: "actionRelevance",
  standalone: true,
})
export class RelevancePipe implements PipeTransform {
  readonly relevanceVariantMap: Record<ActionRelevance, ButtonColor> = {
    "primary-task": "primary",
    "secondary-task": "outline-primary",
  };

  transform(relevance: ActionRelevance) {
    return this.relevanceVariantMap[relevance];
  }
}
