import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  TemplateRef,
} from "@angular/core";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { ItButtonDirective } from "design-angular-kit";
import { RouterLink } from "@angular/router";
import { NgTemplateOutlet } from "@angular/common";
import { Action } from "@/types/action.interface";
import { ResponsiveStyleObjectType } from "@/types/responsive-style-object.type";
import { GridColDirective } from "@/atoms/grid-col.component";
import { IsArrayPipe } from "@/pipes/is-array.pipe";
import { RelevancePipe } from "@/pipes/action-relevance.pipe";
import { ButtonComponent } from "@/atoms/button/button.component";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";
import { absoluteRoutesPaths } from "@/classes/route-utils";

@Component({
  selector: "cup-hero",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FullLayoutComponent,
    RouterLink,
    NgTemplateOutlet,
    ItButtonDirective,
    GridColDirective,
    IsArrayPipe,
    ButtonComponent,
    RelevancePipe,
    ActionButtonComponent,
  ],
  styles: `
    @use "cup";

    .hero-cta-button {
      @include cup.media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: cup.toRem(24);
      }
    }

    .back-btn {
      padding: 0;
      box-shadow: none;
      border: none;
    }
  `,
  template: `
    @let isHrVisible = showHr();

    <ng-container
      *ngTemplateOutlet="sideActions() ? layoutWithActions : fullLayout"
    />

    <ng-template #fullLayout>
      <cup-full-layout>
        <ng-container *ngTemplateOutlet="leftColumn"></ng-container>

        @if (isHrVisible) {
          <hr />
        }
      </cup-full-layout>
    </ng-template>

    <ng-template #layoutWithActions>
      <div class="container-xxl">
        <div class="row gap-4 gap-lg-0">
          <div class="col-lg-8 col-12">
            <ng-container *ngTemplateOutlet="leftColumn"></ng-container>
          </div>

          <div class="col-lg-4 col-12">
            <ul class="d-flex h-100 justify-content-center gap-3 flex-column">
              @for (action of sideActions(); track action) {
                <li>
                  <cup-action-button
                    [action]="action"
                    (actionClick)="
                      action.onClick
                        ? action.onClick()
                        : actionClick.emit(action)
                    "
                  />
                </li>
              }
            </ul>
          </div>
        </div>

        @if (isHrVisible) {
          <hr />
        }
      </div>
    </ng-template>

    <ng-template #leftColumn>
      @let copy = heroCopy();
      @let title = heroTitle();
      @let subtitle = heroSubTitle();
      @let actionButtons = actions();
      @let copyTemplate = heroCopyTemplate();

      <div class="row">
        <div cupGridCol [config]="colConfig()">
          <div class="d-flex align-items-start flex-column gap-3">
            @if (title) {
              <h1>{{ title }}</h1>
            }

            @if (subtitle) {
              <h2 class="h4">{{ subtitle }}</h2>
            }

            @if (copyTemplate) {
              <p class="lead text-secondary">
                <ng-container *ngTemplateOutlet="copyTemplate" />
              </p>
            }

            @if (copy) {
              <p class="lead text-secondary">{{ copy }}</p>
            }

            @if (actionButtons | isArray) {
              <div class="d-flex flex-lg-row flex-column gap-3 w-100">
                @for (action of actionButtons; track action.type) {
                  @let relevance = action.relevance || "primary-task";

                  <a
                    class="justify-content-center"
                    tabindex="0"
                    [routerLink]="action.href"
                    [icon]="action.icon"
                    [relevance]="action.relevance"
                    [itButton]="relevance | actionRelevance"
                    (click)="
                      action.onClick
                        ? action.onClick()
                        : ctaButtonClick.emit(action)
                    "
                  >
                    {{ action.label }}
                  </a>
                }
              </div>
            } @else if (actionButtons !== null) {
              <a
                [href]="actionButtons.href"
                [icon]="actionButtons.icon"
                [relevance]="actionButtons.relevance"
                itButton="primary"
                (click)="ctaButtonClick.emit(actionButtons)"
              >
                {{ actionButtons.label }}
              </a>
            }
          </div>
        </div>
      </div>
    </ng-template>
  `,
})
export class HeroComponent {
  heroTitle = input.required<string>();
  heroSubTitle = input<string>();
  heroCopy = input<string>();
  heroCopyTemplate = input<TemplateRef<void>>();
  actions = input<Action | Action[] | null>(null);
  sideActions = input<Action[]>();
  colConfig = input<ResponsiveStyleObjectType>({ xs: 12 });
  showHr = input(true, {
    transform: booleanAttribute,
  });
  ctaButtonClick = output<Action>();
  actionClick = output<Action>();
  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;
}
