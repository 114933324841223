import { computed, inject, Injectable, signal } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { JwtUser } from "@/types/user.interface";
import { HttpClient, HttpParams } from "@angular/common/http";
import UserPersonalData, {
  GenerateOtpResponse,
  OtpVerifyResponse,
  SaveContactDataResponse,
  UserContactData,
  UserData,
  VerifyAndUpdateAssistitoResponseBody,
} from "@/types/api";
import { map, of, switchMap, takeUntil, tap } from "rxjs";
import { NgHttpCachingHeaders, NgHttpCachingService } from "ng-http-caching";
import { ItNotificationService } from "design-angular-kit";
import { Router } from "@angular/router";
import cancelRequest$ from "@/subjects/cancel-request";

@Injectable({
  providedIn: "root",
})
export class UserService {
  router = inject(Router);
  http = inject(HttpClient);
  oauthService = inject(OAuthService);
  cachingService = inject(NgHttpCachingService);
  notificationService = inject(ItNotificationService);

  user = signal<UserData | null>(null);
  sessionId = signal<number | null>(null);

  userHasContactDataFromSpid = computed(() => {
    return !!this.userJwtPhone && !!this.userJwtEmail;
  });

  userHasContactData = computed(() => {
    return !!this.user()?.contactData;
  });

  userHasPersonalData = computed(() => {
    return !!this.user()?.personalData;
  });

  getUserPersonalData() {
    return this.http
      .get<UserPersonalData>("anagrafe-sanitaria/secure/v1/assistito")
      .pipe(takeUntil(cancelRequest$));
  }

  getTokenTimeRemaining(): number {
    const timeZoneOffsetMillis = new Date().getTimezoneOffset() * 60 * 1_000;
    const tokenExpirationTime =
      this.oauthService.getAccessTokenExpiration() - timeZoneOffsetMillis;
    const now = new Date().getTime() - timeZoneOffsetMillis;
    return tokenExpirationTime - now;
  }

  getUserContactData() {
    return this.http
      .get<{ results: UserContactData }>("dati-contatto/dati-contatto", {
        params: new HttpParams().append(
          "codice_fiscale",
          this.userJwtFiscalCode
        ),
        headers: {
          [NgHttpCachingHeaders.TAG]: "user-contact-data",
        },
      })
      .pipe(
        map((res) => res.results),
        takeUntil(cancelRequest$)
      );
  }

  saveContactData(payload: UserContactDataPayload) {
    return of(null).pipe(
      switchMap(() => {
        if (this.userHasContactData()) {
          return this.updateUserContactData(payload);
        }

        return this.insertUserContactData(payload);
      }),
      tap(({ esito }) => {
        this.cachingService.clearCacheByTag("user-contact-data");
        this.notificationService.success(esito);
      })
    );
  }

  updateUserContactData(payload: UserContactDataPayload) {
    return this.http
      .put<SaveContactDataResponse>("dati-contatto/dati-contatto", payload, {
        headers: {
          [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
        },
      })
      .pipe(takeUntil(cancelRequest$));
  }

  insertUserContactData(payload: UserContactDataPayload) {
    return this.http
      .post<SaveContactDataResponse>("dati-contatto/dati-contatto", payload, {
        headers: {
          [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
        },
      })
      .pipe(takeUntil(cancelRequest$));
  }

  isUserLogged() {
    return !!this.sessionId();
  }

  isTokenExpired() {
    return this.getTokenTimeRemaining() < 0;
  }

  clearSessionAndReload() {
    sessionStorage.clear();
    window.location.reload();
  }

  verifyAndUpdate({ codiceFiscale, telefono, email }: VerifyAndUpdatePayload) {
    const sessionId = this.sessionId();

    if (!sessionId) {
      throw new Error("Session id not found");
    }

    return this.http
      .post<VerifyAndUpdateAssistitoResponseBody>(
        "cupservice/assistito/verify-and-update",
        {
          idSessione: sessionId,
          codiceFiscale,
          telefono,
          email,
        },
        {
          headers: {
            [NgHttpCachingHeaders.TAG]: "verifyAndUpdateUser",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  generateContactsOtp({ email, mobile }: { email?: string; mobile?: string }) {
    return this.http
      .post<GenerateOtpResponse>(
        "dati-contatto/genera-otp",
        {
          ...(mobile && { mobile }),
          ...(email && { email }),
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  verifyOtp(payload: VerifyOtpPayload) {
    return this.http
      .post<OtpVerifyResponse>("dati-contatto/verifica-otp", payload, {
        headers: {
          [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
        },
      })
      .pipe(takeUntil(cancelRequest$));
  }

  removeUserContactsData() {
    return this.http
      .put<{ esito: string }>(
        "dati-contatto/revoca-consenso",
        {
          codice_fiscale: this.userJwtFiscalCode,
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(
        tap(({ esito }) => {
          this.cachingService.clearCacheByTag("user-contact-data");
          this.notificationService.success(esito);
        }),
        takeUntil(cancelRequest$)
      );
  }

  isLogged = computed(() => !!this.sessionId());

  logout() {
    this.oauthService.logOut(true);
    sessionStorage.clear();
    this.sessionId.set(null);
    this.router.navigateByUrl("/");
  }

  get authIdentityClaims() {
    return this.oauthService.getIdentityClaims() as JwtUser;
  }

  get userJwtFullName() {
    return `${this.authIdentityClaims.given_name} ${this.authIdentityClaims.family_name}`;
  }

  get userJwtGivenName() {
    return this.authIdentityClaims.given_name;
  }

  get userJwtFamilyName() {
    return this.authIdentityClaims.family_name;
  }

  get userJwtFiscalCode() {
    return this.authIdentityClaims.sub;
  }

  get userJwtPhone() {
    return this.authIdentityClaims.phone_number;
  }

  get userJwtEmail() {
    return this.authIdentityClaims.email;
  }
}

export type UserContactDataPayload = Omit<
  Partial<UserContactData>,
  "tessera_sanitaria"
> & {
  identificativo?: string;
};

export interface VerifyOtpPayload {
  codice_fiscale: string;
  otp_code?: string;
  otp_mail_code?: string;
  otp_request_id: string;
}

export interface VerifyAndUpdatePayload {
  codiceFiscale: string;
  telefono: string;
  email: string;
}
