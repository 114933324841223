import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import {
  ItBreadcrumbComponent,
  ItBreadcrumbItemComponent,
} from "design-angular-kit";
import { ActivatedRoute } from "@angular/router";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";

@Component({
  selector: "cup-breadcrumb",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FullLayoutComponent,
    ItBreadcrumbComponent,
    ItBreadcrumbItemComponent,
  ],
  styles: `
    @use "cup";

    .breadcrumb {
      display: block;
      margin-bottom: cup.toRem(32);
    }
  `,
  template: `
    <cup-full-layout>
      <it-breadcrumb class="breadcrumb">
        @for (item of breadcrumbItems(); track item.label) {
          <it-breadcrumb-item class="d-inline-block" [href]="item.href">
            {{ item.label }}
          </it-breadcrumb-item>
        }
      </it-breadcrumb>
    </cup-full-layout>

    <div id="content-start" tabindex="0"></div>
  `,
})
export class BreadcrumbComponent {
  breadcrumbItems = toSignal(
    inject(ActivatedRoute).data.pipe(map((data) => data["breadcrumbItems"]))
  );
}
