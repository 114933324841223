import { Directive, input } from "@angular/core";

@Directive()
export class IconBase {
  fontSize = input<number>(16);
  hostStyle = input<Record<string, string>>({});
  color = input<IconColor>("primary");

  readonly colorMap = {
    white: "#ffffff",
    primary: "#007a52",
    warning: "#995c00",
  };
}

type IconColor = "primary" | "warning" | "white";
