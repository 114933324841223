import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { FormBaseUtils } from "@/classes/form-base-utils";
import { JsonPipe } from "@angular/common";
import { map, merge, of, takeUntil } from "rxjs";
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "cup-form-errors",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JsonPipe],
  animations: [
    trigger("stagger", [
      transition(":enter", [
        query(".error-item", [
          style({ opacity: 0, transform: "translateY(4px)" }),
          stagger("200ms", [
            animate(
              "200ms ease-in-out",
              style({ opacity: 1, transform: "translateY(0)" })
            ),
          ]),
        ]),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  styles: `
    @use "cup";

    .form-errors {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: cup.toRem(4);
      margin: cup.toRem(10) 0 0;
    }
  `,
  template: `
    <ul @stagger aria-live="assertive" class="form-errors">
      @for (error of controlErrors(); track error.id) {
        <li class="error-item small-description text-warning" [id]="error.id">
          @switch (error.id) {
            @case ("required") {
              @let text = label();

              @if (text) {
                <ng-container i18n
                  >Il campo {{ text.toLowerCase().replaceAll("*", "") }} è
                  obbligatorio.</ng-container
                >
              } @else {
                <ng-container i18n>Questo campo è obbligatorio</ng-container>
              }
            }
            @case ("fiscalCode") {
              <ng-container i18n>
                Il codice fiscale inserito non è valido.
              </ng-container>
            }
            @case ("email") {
              <ng-container i18n> L'email inserita non è valida.</ng-container>
            }
            @case ("min-length") {
              <ng-container i18n
                >Il campo deve contenere almeno {{ error | json }}
              </ng-container>
            }
            @case ("exactLength") {
              <ng-container i18n>
                Non sono
                {{ error.errorValue?.expectedValue }}
                caratteri
              </ng-container>
            }
            @default {
              {{ error | json }}
              <ng-container i18n>Questo campo non è valido.</ng-container>
            }
          }
        </li>
      }
    </ul>
  `,
})
export class FormErrorsComponent extends FormBaseUtils implements OnInit {
  controlErrors = signal<
    {
      id: string;
      errorValue?: { expectedValue: unknown; currentValue: unknown };
    }[]
  >([]);

  label = input<string | undefined>("");

  ngOnInit() {
    merge(of(this.control.value), this.control.valueChanges)
      .pipe(
        map(() =>
          Object.entries(this.control.errors || {}).map(([key, value]) => ({
            id: key,
            errorValue: value,
          }))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((errors) => this.controlErrors.set(errors));
  }
}
