import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  viewChildren,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { InputComponent } from "@/atoms/input/input.component";
import { RadioChipComponent } from "@/atoms/radio-chip/radio-chip.component";
import { of, startWith, takeUntil } from "rxjs";
import { Destroyable } from "@/classes/destroyable";
import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import {
  ItCarouselComponent,
  ItCarouselItemComponent,
} from "design-angular-kit";

@Component({
  selector: "cup-contacts-form",

  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    RadioChipComponent,
    ReactiveFormsModule,
    NgTemplateOutlet,
    ItCarouselComponent,
    ItCarouselItemComponent,
    AsyncPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContactsFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ContactsFormComponent,
    },
  ],
  styles: ``,
  template: `
    <ng-container [formGroup]="form">
      <div class="d-flex flex-column gap-lg-5 gap-3">
        <ng-container *ngTemplateOutlet="inputBlock; context: emailContext" />
        <ng-container *ngTemplateOutlet="inputBlock; context: phoneContext" />
      </div>

      <ng-template
        #inputBlock
        let-id="id"
        let-label="label"
        let-inputName="name"
        let-placeholder="placeholder"
        let-ownerInputName="ownerInputName"
      >
        <div class="d-flex gap-3 flex-column">
          <h2 class="h5" i18n>{{ label }}</h2>

          @if (showOwnerChips()) {
            <p i18n>A chi appartiene?</p>

            <it-carousel
              #carousel
              [fullCarousel]="true"
              class="cup-carousel"
              type="default"
            >
              @for (chip of ownerChips; track chip.value) {
                <it-carousel-item>
                  <cup-radio-chip
                    [isToggleMode]="false"
                    [label]="chip.label"
                    [value]="chip.value"
                    [currentValue]="
                      getCurrentValueObservable(ownerInputName) | async
                    "
                    [formControlName]="ownerInputName"
                  />
                </it-carousel-item>
              }
            </it-carousel>
          }

          <cup-input
            #input
            class="mt-4"
            [isReadonly]="readonlyFieldsOnly()"
            [formGroup]="form"
            [name]="inputName"
            [placeholder]="placeholder"
            [label]="label"
            isRequired="true"
            [inputId]="id"
          />
        </div>
      </ng-template>
    </ng-container>
  `,
})
export class ContactsFormComponent
  extends Destroyable
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit
{
  readonlyFieldsOnly = input(false, {
    transform: booleanAttribute,
  });
  inputs = viewChildren<InputComponent>("input");

  carousel = viewChildren("carousel", {
    read: ElementRef,
  });

  getCurrentValueObservable(name: string) {
    const control = this.form.get(name);

    if (!control) {
      return of("");
    }

    return control.valueChanges.pipe(startWith(control?.value || ""));
  }

  readonly emailContext = {
    label: $localize`Indirizzo email`,
    name: "email",
    ownerInputName: "emailOwner",
    id: "email-address",
    placeholder: "Inserisci l'indirizzo email",
  };

  readonly phoneContext = {
    label: $localize`Numero di cellulare`,
    name: "phoneNumber",
    ownerInputName: "phoneNumberOwner",
    id: "phone-number",
    placeholder: "Inserisci il numero di cellulare",
  };

  readonly formFields = [
    "email",
    "emailOwner",
    "phoneNumber",
    "phoneNumberOwner",
  ] as const;

  readonly ownerChips = [
    {
      label: $localize`Me stesso`,
      value: "A",
    },
    {
      label: $localize`Familiare`,
      value: "F",
    },
    {
      label: $localize`Caregiver`,
      value: "C",
    },
  ];

  showOwnerChips = input(true, {
    transform: booleanAttribute,
  });

  formBuilder = inject(FormBuilder);

  onTouched!: () => void;

  markAsTouched(): void {
    Object.values(this.form.controls)
      .filter((control) => control.invalid)
      .forEach((control) => {
        control.markAsTouched();
        control.updateValueAndValidity();
      });

    const invalidControl = Object.values(this.form.controls).find(
      (control) => control.invalid
    );

    const ref = this.inputs().find((input) => {
      return input.control === invalidControl;
    });

    if (!ref) return;

    ref.inputRef.focus();
  }

  form!: FormGroup;

  ngOnInit() {
    this.form = this.formBuilder.nonNullable.group({
      email: ["", [Validators.required, Validators.email]],
      phoneNumber: ["", [Validators.required]],
      ...(this.showOwnerChips() && {
        emailOwner: ["A", [Validators.required]],
        phoneNumberOwner: ["A", [Validators.required]],
      }),
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      for (const carousel of this.carousel()) {
        const element = carousel.nativeElement as HTMLElement;

        element.querySelectorAll("li")?.forEach((element) => {
          element.setAttribute("tabindex", "-1");
        });
      }
    });
  }

  registerOnChange(onChange: () => void) {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(onChange);
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      return this.form.disable();
    }

    this.form.enable();
  }

  writeValue(value: Required<typeof this.form.value>) {
    if (!value) return;

    this.form.setValue(value, { emitEvent: false });
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return this.formFields.reduce((acc, formField) => {
      const formControl = this.form.controls[formField];
      const controlErrors = formControl?.errors;

      return {
        ...acc,
        ...(controlErrors ? { [formField]: controlErrors } : {}),
      };
    }, {});
  }

  protected readonly startWith = startWith;
}
