import { Subject } from "rxjs";
import { Directive, OnDestroy } from "@angular/core";

@Directive()
export class Destroyable implements OnDestroy {
  destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next();
  }
}
