export class RouteUtils {
  replacePathParams = (path: string, params: Record<string, string | number>) =>
    Object.entries(params).reduce(
      (acc, [key, value]) => acc.replace(`:${key}`, value.toString()),
      path
    );
}

export const relativeRoutesPaths = Object.seal({
  othersReservations: $localize`prenotazioni-per-altri`,
  othersReservationsDetails: $localize`prenotazioni-per-altri/:id`,
  userProfile: $localize`profilo-e-contatti`,
  userContactData: $localize`profilo-e-contatti/dati-di-contatto`,
  userContactDataSPID: $localize`profilo-e-contatti/dati-di-contatto/spid`,
  userReservations: $localize`le-mie-prenotazioni`,
  reservationDetail: $localize`le-mie-prenotazioni/:id`,
  reservationHistory: $localize`le-mie-prenotazioni/storico`,
  reservationHistoryDetail: $localize`le-mie-prenotazioni/storico/:id`,
  newReservation: $localize`le-mie-prenotazioni/nuova-prenotazione`,
  reservationConfirmed: $localize`le-mie-prenotazioni/nuova-prenotazione/:id/conferma`,
  newReservationUploadRecipe: $localize`carica-ricetta`,
  recipeDetailFromNewReservation: $localize`dettaglio-ricetta/:recipe-id`,
  newReservationAddContacts: $localize`aggiungi-contatti`,
  accessibility: $localize`accessibilita`,
});

export const absoluteRoutesPaths = Object.seal({
  othersReservations: `/${relativeRoutesPaths.othersReservations}`,
  othersReservationDetail: `/${relativeRoutesPaths.othersReservationsDetails}`,
  userProfile: `/${relativeRoutesPaths.userProfile}`,
  userContactData: `/${relativeRoutesPaths.userContactData}`,
  userContactDataSPID: `/${relativeRoutesPaths.userContactDataSPID}`,
  userReservations: `/${relativeRoutesPaths.userReservations}`,
  reservationDetail: `/${relativeRoutesPaths.reservationDetail}`,
  reservationHistory: `/${relativeRoutesPaths.reservationHistory}`,
  reservationHistoryDetail: `/${relativeRoutesPaths.reservationHistoryDetail}`,
  newReservation: `/${relativeRoutesPaths.newReservation}`,
  newReservationConfirmed: (id: number) =>
    `/${relativeRoutesPaths.reservationConfirmed.replace(":id", `${id}`)}`,
  newReservationUploadRecipe: $localize`/le-mie-prenotazioni/nuova-prenotazione/carica-ricetta`,
  newReservationRecipeDetail: $localize`/le-mie-prenotazioni/nuova-prenotazione/dettaglio-ricetta/:recipe-id`,
  newReservationAddContacts: $localize`/le-mie-prenotazioni/nuova-prenotazione/aggiungi-contatti`,
  accessibility: `/${relativeRoutesPaths.accessibility}`,
});

export const routeLinks = Object.seal({
  home: {
    href: "/",
    label: "Home",
  },
  othersReservations: {
    href: absoluteRoutesPaths.othersReservations,
    label: $localize`Prenotazioni per altri`,
  },
  reservationConfirmed: {
    href: absoluteRoutesPaths.newReservationConfirmed,
    label: $localize`Prenotazione confermata`,
  },
  myReservations: {
    href: absoluteRoutesPaths.userReservations,
    label: $localize`Le mie prenotazioni`,
  },
  newReservation: {
    href: absoluteRoutesPaths.newReservation,
    label: $localize`Nuova prenotazione`,
  },
  newReservationRecipeDetail: {
    href: absoluteRoutesPaths.newReservationRecipeDetail,
    label: $localize`Dettaglio ricetta`,
  },
  newReservationUploadRecipe: {
    href: absoluteRoutesPaths.newReservationUploadRecipe,
    label: $localize`Carica una ricetta`,
  },
  reservationHistory: {
    href: absoluteRoutesPaths.reservationHistory,
    label: $localize`Storico`,
  },
  userProfile: {
    href: absoluteRoutesPaths.userProfile,
    label: $localize`Profilo e contatti`,
  },
  userContactData: {
    href: absoluteRoutesPaths.userContactData,
    label: $localize`Dati di contatto`,
  },
  userContactDataSPID: {
    href: absoluteRoutesPaths.userContactDataSPID,
    label: $localize`Dati SPID`,
  },
  reservationDetail: (reservationId: number) => ({
    href: new RouteUtils().replacePathParams(
      absoluteRoutesPaths.reservationDetail,
      {
        id: reservationId,
      }
    ),
    label: `#${reservationId}`,
  }),
  reservationHistoryDetail: (reservationId: number) => ({
    href: new RouteUtils().replacePathParams(
      absoluteRoutesPaths.reservationHistoryDetail,
      {
        id: reservationId,
      }
    ),
    label: `#${reservationId}`,
  }),
  newReservationAddContacts: {
    href: absoluteRoutesPaths.newReservationAddContacts,
    label: $localize`Aggiungi contatti`,
  },
  accessibility: {
    href: absoluteRoutesPaths.accessibility,
    label: $localize`Accessibilità`,
  },
});
