import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
} from "@angular/core";
import {
  IconColor,
  IconName,
  ItIconComponent,
  ItSpinnerComponent,
} from "design-angular-kit";
import { ActionRelevance } from "@/types/action-relevance.type";
import { ActionSeverity } from "@/types/action-severity.type";

@Component({
  selector: "a[itButton], button:not([progress])[itButton]",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ItIconComponent, ItSpinnerComponent],
  standalone: true,
  template: `
    @let buttonIcon = icon();
    @let position = iconPosition();
    @let label = loadingLabel();

    @if (!label && buttonIcon && position === "left") {
      <it-icon [name]="buttonIcon" [color]="calculatedIconColor" />
    }

    @if (label) {
      <span aria-live="polite" aria-atomic="true">
        {{ label }}
      </span>
    } @else {
      <ng-content></ng-content>
    }

    @if (label) {
      <it-spinner small="true" />
    }

    @if (!label && buttonIcon && position === "right") {
      <it-icon [name]="buttonIcon" [color]="calculatedIconColor" />
    }
  `,
  host: {
    class: "d-flex gap-2",
    "[disabled]": "!!loadingLabel()",
  },
})
export class ButtonComponent {
  icon = input<IconName>();
  iconPosition = input<"left" | "right">("left");
  relevance = input<ActionRelevance | undefined>("primary-task");
  severity = input<ActionSeverity>("normal");
  loadingLabel = input<string>("");
  isDisabled = input<boolean>(false);
  iconColor = input<IconColor | undefined>();

  elementRef = inject(ElementRef);

  getColorBySeverity() {
    return this.severity() === "high"
      ? "danger"
      : this.relevance() === "primary-task"
        ? "white"
        : "primary";
  }

  get calculatedIconColor() {
    const isFocused = this.elementRef.nativeElement.matches(":focus-visible");
    const inputColor = this.iconColor();

    if (inputColor) {
      return inputColor;
    }

    if (isFocused) {
      return this.getColorBySeverity();
    }

    if (this.elementRef.nativeElement.disabled) {
      return "secondary";
    }

    if (this.relevance() === "primary-task") {
      return "white";
    }

    return this.getColorBySeverity();
  }
}
