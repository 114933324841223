import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IconBase } from "@/classes/icon-base";

@Component({
  selector: "cup-warning-circle-icon",
  standalone: true,
  host: { "[style]": "hostStyle()" },
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg
      [style.font-size.rem]="fontSize() / 16"
      [style.color]="colorMap[color()]"
      class="custom-icon"
      aria-hidden="true"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM11.5 14.2V5.7H12.7V14.2H11.5ZM12.6 18.3V16.5H11.4V18.3H12.6Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class WarningCircleIconComponent extends IconBase {}
