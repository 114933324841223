import {
  booleanAttribute,
  Directive,
  input,
  OnInit,
  signal,
  viewChild,
} from "@angular/core";
import { FormBaseUtils } from "@/classes/form-base-utils";
import { filter, map, startWith, takeUntil } from "rxjs";
import { InputRefDirective } from "@/directives/input-ref.directive";
import { TouchedChangeEvent } from "@angular/forms";

@Directive()
export class FormUtils extends FormBaseUtils implements OnInit {
  ref = viewChild(InputRefDirective);

  get inputRef() {
    return this.ref()?.elementRef.nativeElement as HTMLInputElement;
  }

  label = input<string>();
  min = input<string>();
  inputId = input.required<string>();
  ariaLabel = input<string>();
  class = input<string>();
  ariaLabelledby = input<string>();
  ariaDescribedby = input<string>();
  isReadonly = input(false, {
    transform: booleanAttribute,
  });

  isRequired = input(false, {
    transform: booleanAttribute,
  });

  placeholder = input<string>();
  helpText = input<string>();
  isDisabled = input(false);

  isTouched = signal(false);
  isFocused = signal(false);
  isInvalid = signal(false);

  get ariaDescribedBy() {
    let messages = this.errorEntries.map((err) => err.id);

    if (this.helpText()) {
      messages = [`${this.inputId()}-help-text`, ...messages];
    }

    if (!this.ariaDescribedby()) {
      return messages.join(" ");
    }

    return [this.ariaDescribedby(), ...messages].join(" ");
  }

  ngOnInit() {
    this.control.statusChanges
      .pipe(
        startWith(this.control.status),
        map((status) => status === "INVALID"),
        takeUntil(this.destroy$)
      )
      .subscribe(this.isInvalid.set);

    this.control.events
      .pipe(
        startWith(this.control.touched),
        filter(
          (event) => event === true || event instanceof TouchedChangeEvent
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.isTouched.set(true));
  }
}
