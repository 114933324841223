import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  signal,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Destroyable } from "@/classes/destroyable";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs";

@Component({
  selector: "cup-radio-chip",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioChipComponent,
    },
  ],
  styles: `
    @use "cup";

    :focus-visible {
      outline: none;
    }

    .hour-chip {
      min-width: auto;
      margin: 0;
      padding: cup.toRem(4) cup.toRem(16);

      &[aria-checked="true"] {
        background: cup.$color-background-primary;

        .chip-label {
          color: cup.$color-text-inverse;
        }
      }
    }
  `,
  template: `
    <button
      (click)="onClick()"
      [attr.aria-checked]="isChecked()"
      [disabled]="isDisabled()"
      role="radio"
      type="button"
      class="chip chip-simple chip-success hour-chip"
    >
      <span class="chip-label">{{ label() }}</span>
    </button>
  `,
})
export class RadioChipComponent
  extends Destroyable
  implements ControlValueAccessor, OnInit
{
  onChange!: (value: string) => void;
  onTouched!: () => void;

  currentValue = input.required<string | undefined>();
  isToggleMode = input(true);
  value = input.required<string>();
  label = input.required<string>();

  isTouched = signal(false);
  isDisabled = signal(false);
  isChecked = signal(false);

  constructor() {
    super();

    toObservable(this.currentValue)
      .pipe(
        filter(() => this.isChecked() && this.currentValue() !== this.value()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.isChecked.set(false));
  }

  ngOnInit() {
    this.isChecked.set(this.currentValue() !== this.value());
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled.set(isDisabled);
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    this.isChecked.set(this.value() === value);
  }

  onClick() {
    if (this.isToggleMode() && this.isChecked()) {
      this.onChange("");
      return;
    }

    this.markAsTouched();
    this.isChecked.set(true);
    this.onChange(this.value());
  }

  markAsTouched() {
    if (this.isTouched()) return;
    this.onTouched();
    this.isTouched.set(true);
  }
}
