import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormUtils } from "@/classes/form-utils";
import { FormErrorsComponent } from "@/atoms/form-errors/form-errors.component";
import { WarningCircleIconComponent } from "@/atoms/icons/warning-circle-icon.component";
import { fadeInAnimation } from "@/animations/icon-fade-in";
import { InputRefDirective } from "@/directives/input-ref.directive";

@Component({
  selector: "cup-input",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormErrorsComponent,
    WarningCircleIconComponent,
    InputRefDirective,
  ],
  animations: [fadeInAnimation],
  styles: `
    @use "cup";

    .form-control {
      border-radius: cup.toRem(4) !important;
      border: 0.1rem solid cup.$color-border-secondary;
    }

    .form-group {
      label.active {
        @extend .caption;
        transform: translateY(calc(-100% - #{cup.toRem(4)}));
      }

      &:has(.form-control.ng-invalid.ng-touched) {
        .form-label {
          color: cup.$color-text-warning;
        }

        .form-control {
          border-color: cup.$color-border-warning;
        }
      }

      label:not(.form-label--date) ~ div.position-relative > input {
        padding-right: cup.toRem(40);
      }
    }

    .form-label--date {
      padding: 0;
      margin: 0 0 cup.toRem(4);
      position: static;
      color: cup.$color-text-secondary;
      transform: none !important;
      @extend .caption;
    }

    cup-warning-circle-icon {
      position: absolute;
      right: cup.toRem(8);
      top: cup.toRem(6);
    }

    .help-text {
      margin-top: cup.toRem(10);
      margin-left: cup.toRem(7);
    }
  `,
  template: `
    @let inputLabel = label();
    @let isTouchedAndInvalid = isInvalid() && isTouched();

    <div [formGroup]="formGroup()" class="form-group">
      @if (inputLabel) {
        <label
          class="form-label"
          [class.form-label--date]="type() === 'date'"
          [attr.for]="inputId()"
          [class.active]="isActive"
        >
          {{ inputLabel }}
          @if (isRequired()) {
            &ast;
          }
        </label>
      }

      @if (ariaLabel()) {
        <div class="sr-only" [id]="ariaLabelId">{{ ariaLabel() }}</div>
      }

      <div class="position-relative">
        <input
          cupInputRef
          [min]="min()"
          [id]="inputId()"
          [name]="name()"
          [type]="type()"
          [readOnly]="isReadonly()"
          [formControlName]="name()"
          [placeholder]="placeholder()"
          [attr.aria-labelledby]="ariaLabel() ? ariaLabelId : undefined"
          [attr.aria-invalid]="control.invalid"
          [attr.aria-describedby]="ariaDescribedBy"
          [attr.aria-required]="isRequired()"
          [class]="class() + ' form-control'"
          [maxlength]="maxlength()"
          (focus)="isFocused.set(true)"
          (blur)="isFocused.set(false)"
        />

        @if (isTouchedAndInvalid) {
          <cup-warning-circle-icon
            [@fadeIn]="'visible'"
            color="warning"
            [fontSize]="24"
          />
        }
      </div>

      @if (helpText(); as text) {
        <p
          [id]="inputId() + '-help-text'"
          class="help-text small-description text-secondary"
        >
          {{ text }}
        </p>
      }

      @if (isTouchedAndInvalid) {
        <cup-form-errors
          [label]="label()"
          [formGroup]="formGroup()"
          [name]="name()"
        />
      }
    </div>
  `,
})
export class InputComponent extends FormUtils {
  type = input("text");

  maxlength = input<number>(100);

  get ariaLabelId() {
    return `${this.inputId()}-label`;
  }

  get hasValue() {
    return !!this.control.value;
  }

  get isActive() {
    return this.hasValue || !!this.placeholder() || this.isFocused();
  }
}
